.swiper {
  width: 100%;
  height: auto;
}
.swiper .swiper-slide {
  height: 618px;
  line-height: 300px;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.abstract-shapeS  {
  display: flex;
  align-items: center;
  width: 100%;
  height: 65px;
  background-color: rgba(0, 0, 0, 0.6);
}


/* Media query for tablets (768px width and below) */
@media (max-width: 768px) {
  .swiper .swiper-slide {
    height: 400px;
    line-height: 400px;
  }
  .abstract-shapeS  {
    width: 180px;
    height: 100px;
  }
}
/* Media query for mobile devices (480px width and below) */
@media (max-width: 480px) {
  .swiper .swiper-slide {
    height: 280px;
    line-height: 280px;
  }
  .abstract-shapeS  {
    width: 100%;
    height: 45px;
  }

}
