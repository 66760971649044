@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  background-color: #BEBEBE ;
  /* #FFF5EE	; */
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body {
  margin: 0;
}
