
.card {
    width: 450px; /* Adjust to your desired width */
    height: 300px; /* Adjust to your desired height */
  }
 
  .modal {
    z-index: 1000;
  }
  /* Apply your custom styles to the modal */
.custom-modal {
  /* Your custom styles for the modal here */
  background-color: transparent; /* Remove default background color */
  border: none; /* Remove default border */
  /* Add any other styles you want */
}

/* Style the modal content */
.custom-modal .grid {
  background-color: #fff; /* Set the background color for the modal content */
  border-radius: 8px; /* Add border radius to the content */
  padding: 20px; /* Add padding to the content */
  /* Add other content styles as needed */
}
  /* Media query for tablets (768px width and below) */
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .card {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        width: 350px; /* Adjust to your desired width */
        height: 300px; /* Adjust to your desired height */
      }
  }
  /* Media query for mobile devices (480px width and below) */
  @media (max-width: 480px) {
    .card {
        width: 320px; /* Adjust to your desired width */
        height: 250px; /* Adjust to your desired height */
      }
  
  }
  