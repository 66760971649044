.swiper {
    width: 100%;
    height: auto;
  }
  .swiper .swiper-slide {
    height: 618px;
    line-height: 300px;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .abstract-shape {
    display: flex;
    align-items: center;
    width: 215px;
    height: 85px;
    background-color: rgba(231, 184, 71, 0.9);
    border-radius: 60% 50% 50% 50% / 80% 60% 50% 25%;
  }
  
  
  /* Media query for tablets (768px width and below) */
  @media (max-width: 768px) {
    .swiper .swiper-slide {
      height: 400px;
      line-height: 400px;
    }
    .abstract-shape {
      width: 180px;
      height: 100px;
    }
  }
  /* Media query for mobile devices (480px width and below) */
  @media (max-width: 480px) {
    .swiper .swiper-slide {
      height: 280px;
      line-height: 280px;
    }
    .abstract-shape {
      width: 150px;
      height: 65px;
    }
  
  }
  