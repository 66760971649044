/* Targeting the specific Swiper instance */
#uns .swiper6 {
    width: 100%;
    height: auto;
  }
  
  /* Styles for the individual Swiper slides */
  #uns .swiper6 .swiper-slide {
    height: 100%;
  }
  
  /* Image styles within Swiper slides */
  #uns .swiper6 .swiper-slide img {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-size: cover;
    object-position: center;
  }
  